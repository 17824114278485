<template>
  <div class="apartments-container">
    <h1 id="apartments">DIE WOHNUNGEN</h1>
    <div class="title-separator-container">
      <div class="title-separator"/>
    </div>
    <v-row class="apartments-list-container">
      <v-col cols="12">
        <v-card flat>
          <v-card-title>
            <b>Wohnung “Nussbaum“</b>: 113 m² Wohnfläche, 204 m² Garten und 26 m² Terrasse
            <v-alert type="success" dense :icon="false">
              Verfügbar
            </v-alert>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-left">
                Diese helle Wohnung besticht durch ihre Ruhelage im sonnigen Gartengefüge. <br/>
                Die Großzügigkeit wird durch zwei separate Eingänge zum Grundstück unterstrichen. <br/><br/>
                Der Nussbaum bietet den idealen Platz im Garten in lauen Sommernächten. <br/><br/>
                Der flexible Grundriss kann - Generationen- übergreifend - mit einfachen Baumaßnahmen
                adaptiert werden. <br/><br/>
                Eine beispielgebende Innenarchitektur zeigt sich in den Videos
                (Inneneinrichtung optional und frei gestaltbar)
              </v-col>
              <v-col cols="12" class="pa-5">
                <v-row>
                  <v-col cols="12" class="text-left pa-1">
                    <h5>Rundgang durch die Wohnung:</h5>
                  </v-col>
                  <v-col cols="12" class="pa-1 text-left">
                    <vimeo-player id="581138309" thumbnail="/images/apartments/nussbaum/nussbaum_image_1.jpg"/>
                    <vimeo-player id="581138345" thumbnail="/images/apartments/nussbaum/nussbaum_image_11.jpg"/>
                  </v-col>
                  <v-col cols="12" class="text-left pa-1">
                    <h5>Fotogalerie:</h5>
                  </v-col>
                  <v-col v-for="(image, index) in gallery.apartment1.images" :key="'gallery_apartment_1_image_' + index"
                         class="d-flex child-flex pa-1" cols="4" sm="4" md="3" lg="3" xl="2">
                    <v-img :src="image" aspect-ratio="1.7778" @click="gallery.apartment1.index = index"/>
                  </v-col>
                  <v-col cols="12" class="text-left pa-1">
                    <h5>Pläne:</h5>
                  </v-col>
                  <v-col v-for="(image, index) in gallery.apartment1.plans.images"
                         :key="'gallery_apartment_plans_1_image_' + index"
                         class="d-flex child-flex pa-1" cols="4" sm="4" md="3" lg="3" xl="2">
                    <v-img :src="image" aspect-ratio="1.7778" @click="gallery.apartment1.plans.index = index">
                      <a :href="gallery.apartment1.plans.files[index]" download>
                        <v-icon small color="white">{{icons.download}}</v-icon>
                      </a>
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn outlined color="success" x-large @click="goToContact()">
              Jetzt anfragen
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat>
          <v-card-title>
            <b>Wohnung “Gartenfreude“</b>: 116 m² Wohnfläche, 171 m² Garten und 36 m² Terrasse
            <v-alert type="success" dense :icon="false">
              Verfügbar
            </v-alert>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-left">
                Auf zwei Ebenen erfüllt diese Wohnung den Wunsch nach Ruhe und Erholung im sonnigen Garten. <br/>
                Der Garten erstreckt sich von Westen über Süden nach Osten – also ideal für umfangreiches Gestalten
                und Verweilen im Grünen! <br/><br/>
                Eine beispielgebende Innenarchitektur zeigt sich in den Videos
                (Inneneinrichtung optional und frei gestaltbar)
              </v-col>
              <v-col cols="12" class="pa-5">
                <v-row>
                  <v-col cols="12" class="text-left pa-1">
                    <h5>Rundgang durch die Wohnung:</h5>
                  </v-col>
                  <v-col cols="12" class="pa-1 text-left">
                    <vimeo-player id="581144473" thumbnail="/images/apartments/gartenfreude/gartenfreude_image_2.jpg"/>
                    <vimeo-player id="581144256" thumbnail="/images/apartments/gartenfreude/gartenfreude_image_4.jpg"/>
                  </v-col>
                  <v-col cols="12" class="text-left  pa-1">
                    <h5>Fotogalerie:</h5>
                  </v-col>
                  <v-col v-for="(image, index) in gallery.apartment2.images" :key="'gallery_apartment_2_image_' + index"
                         class="d-flex child-flex pa-1" cols="4" sm="4" md="3" lg="3" xl="2">
                    <v-img :src="image" aspect-ratio="1.7778" @click="gallery.apartment2.index = index"/>
                  </v-col>
                  <v-col cols="12" class="text-left pa-1">
                    <h5>Pläne:</h5>
                  </v-col>
                  <v-col v-for="(image, index) in gallery.apartment2.plans.images"
                         :key="'gallery_apartment_plans_2_image_' + index"
                         class="d-flex child-flex pa-1" cols="4" sm="4" md="3" lg="3" xl="2">
                    <v-img :src="image" aspect-ratio="1.7778" @click="gallery.apartment2.plans.index = index">
                      <a :href="gallery.apartment2.plans.files[index]" download>
                        <v-icon small color="white">{{icons.download}}</v-icon>
                      </a>
                    </v-img>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn outlined color="success" x-large @click="goToContact()">
              Jetzt anfragen
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card flat>
          <v-card-title>
            <b>Wohnung “Baumkrone“</b>
            <v-alert type="error" dense :icon="false">
              Nicht verfügbar
            </v-alert>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-left">
                Die Natur umarmt diesen Wohntraum! <br/>
                Die zweigeschossige Wohnung vereint, in unmittelbarer Nähe zum Nussbaum, zwei geschützte Außenbereiche
                inkl. privater Terrasse, sowie großzügige Raumhöhen mit einem kompakten Grundriss.
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat>
          <v-card-title>
            <b>Wohnung “Luftoase“</b>
            <v-alert type="error" dense :icon="false">
              Nicht verfügbar
            </v-alert>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-left">
                Diese zentral gelegene Einheit ist ein wahres Raumwunder und erreicht durch die offene Gestaltung
                ein Maximum an Effizienz. <br/>
                Der loungeartige Wohnbereich im Obergeschoss bildet einen idealen Rückzugsort mit Fernblick in die
                Berglandschaft.
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat>
          <v-card-title>
            <b>Wohnung “Sonneninsel“</b>
            <v-alert type="error" dense :icon="false">
              Nicht verfügbar
            </v-alert>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row>
              <v-col cols="12" class="text-left">
                Die Maisonette wird von natürlichem Licht wahrlich liebkost und umschmeichelt. <br/>
                Der offene Wohn und Eingangsbereich mit den großzügigen Fassadenöffnungen akzentuiert diese
                einmalige Lage im Süden des Gebäudes <br/><br/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-alert type="success" class="mt-10 apartments-information" :icon="icons.info" prominent>
          <v-row>
            <v-col cols="12" class="text-left pb-0">
              <b>Verkauf</b>: ab sofort, auf Wunsch Mitwirkung bei der Detailplanung des
              Wohnungs-Innenbereichs und des privaten Gartens
            </v-col>
            <v-col cols="12" class="text-left pt-0">
              <b>Erstbezug</b>: 1. Halbjahr 2023
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <vue-gallery :index="gallery.apartment1.index" :images="gallery.apartment1.images" :id="gallery.apartment1.id"/>
    <vue-gallery :index="gallery.apartment2.index" :images="gallery.apartment2.images" :id="gallery.apartment2.id"/>
    <vue-gallery :index="gallery.apartment1.plans.index" :images="gallery.apartment1.plans.images"
                 :id="gallery.apartment1.plans.id"/>
    <vue-gallery :index="gallery.apartment2.plans.index" :images="gallery.apartment2.plans.images"
                 :id="gallery.apartment2.plans.id"/>
  </div>
</template>

<script>
import { mdiInformationOutline, mdiDownload } from '@mdi/js';
import VueGallery from 'vue-gallery';

const VimeoPlayer = () => import(/* webpackChunkName: "VimeoPlayer" */ '../VimeoPlayer');


export default {
  name: "Apartments",
  components: {
    VueGallery,
    VimeoPlayer,
  },
  data: () => ({
    icons: {
      info: mdiInformationOutline,
      download: mdiDownload
    },
    gallery: {
      apartment1: {
        id: 'apartment_1_gallery',
        index: null,
        images: [
          '/images/apartments/nussbaum/nussbaum_image_3.jpg',
          '/images/apartments/nussbaum/nussbaum_image_4.jpg',
          '/images/apartments/nussbaum/nussbaum_image_17.jpg',
          '/images/apartments/nussbaum/nussbaum_image_12.jpg'
        ],
        plans: {
          id: 'apartment_plans_1_gallery',
          index: null,
          images: [
            '/images/plans/nussbaum_image_1.jpg',
            '/images/plans/nussbaum_image_2.jpg'
          ],
          files: [
            '/images/plans/Wohnung_Nussbaum_Details.pdf',
            '/images/plans/Wohnung_Nussbaum_Grundriss.pdf'
          ]
        }
      },
      apartment2: {
        id: 'apartment_2_gallery',
        index: null,
        images: [
          '/images/apartments/gartenfreude/gartenfreude_image_6.jpg',
          '/images/apartments/gartenfreude/gartenfreude_image_5.jpg',
          '/images/apartments/gartenfreude/gartenfreude_image_7.jpg',
          '/images/apartments/gartenfreude/gartenfreude_image_16.jpg'
        ],
        plans: {
          id: 'apartment_plans_2_gallery',
          index: null,
          images: [
            '/images/plans/gartenfreude_image_1.jpg',
            '/images/plans/gartenfreude_image_2.jpg'
          ],
          files: [
            '/images/plans/Wohnung_Gartenfreude_Details.pdf',
            '/images/plans/Wohnung_Gartenfreude_Grundriss.pdf'
          ]
        }
      }
    }
  }),
  methods: {
    goToContact() {
      this.globalEvents.$emit('goToElement', '#contact');
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/variables.scss";
@import "../../assets/scss/layout.scss";
.apartments-container {
  padding-top: 60px;
  margin-left: 185px;
  margin-right: 185px;
  text-align: center;
  @include MQ(L) {
    margin-left: 135px;
    margin-right: 135px;
  }
  @include MQ(M) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @include MQ(S) {
    margin-left: 25px;
    margin-right: 25px;
  }
  @include MQ(XS) {
    padding-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
  }
  h1 {
    font-size: 3em;
    @include MQ(XS) {
      font-size: 2em;
    }
  }
  .title-separator-container {
    display: flex;
    justify-content: center;
    .title-separator {
      width: 150px;
      height: 8px;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
  .apartments-list-container {
    .v-card {
      margin-top: 40px;
      border:  1px solid rgba(0, 0, 0, 0.2);
      .v-card__title {
        position: relative;
        @include MQ(XS) {
          b {
            display: contents;
          }
        }
        .title-separator {
          display: block;
          width: 150px;
          height: 8px;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        .v-alert {
          position: absolute;
          right: 8px;
          top: 8px;
          margin: 0;
          @include MQ(XS) {
            position: relative;
            width: 100%;
            right: 0;
          }
        }
      }
      .v-card__text {
        font-size: 1.3em;
        @include MQ(XS) {
          font-size: 1em;
        }
        .v-image {
          cursor: pointer;
          a {
            position: absolute;
            padding: 5px;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
          }
        }
      }
      .v-card__actions {
        .v-btn {
          @include MQ(XS) {
            width: 100%;
          }
        }
      }
    }
    .apartments-information {
      font-size: 1.3em;
      @include MQ(XS) {
        font-size: 1em;
      }
    }
  }
}
</style>
